.productWrapperConfirm-admin img {
  max-width: 100%;
}

.productWrapperConfirm {
  margin-bottom: 40px;
}

.productWrapperConfirm .main__conditions {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 24px -15px 32px -15px;
  padding-bottom: 10px;
}
.productWrapperConfirm .main__conditions-item {
  width: 33.332%;
  padding: 0 15px;
  margin-bottom: 15px;
}

.productWrapperConfirm .main__conditions-item-label {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.productWrapperConfirm .main__conditions-item-text {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.productWrapperConfirm .main__conditions-item-text span {
  color: #b5b5c3;
  font-size: 16px;
  font-weight: normal;
}
.productWrapperConfirm .checkout_confirmList {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 24px -15px 0 -15px;
}

.productWrapperConfirm .checkout_confirm {
  padding-top: 32px;
  border-top: 1px solid #ecf0f3;
}

.productWrapperConfirm .checkout_confirm-item {
  width: 50%;
  padding: 0 15px;
  margin-bottom: 24px;
}

.checkout_confirm-item-heading {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.checkout_confirm-item-heading h6 {
  margin: 0;
}

.checkout_confirm-item-edit {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  color: #47c8f5;
  font-size: 14px;
  margin-left: 10px;
  margin-top: -2px;
}
.checkout_confirm-item-edit::before {
  content: '\e911';
  font-family: icomoon, sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.checkout_confirm-item-groupItem {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.productWrapperConfirm .total__info {
  margin-top: 32px;
  margin-bottom: 24px;
}

.productWrapperConfirm .price__info {
  margin-top: 24px;
  background: #f3f6f9;
  border: 1px solid #e5eaee;
  border-radius: 4px;
  padding: 16px 24px 15px 24px;
  display: flex;
  align-items: flex-start;
}

.productWrapperConfirm .price__info-price {
  display: flex;
  flex-direction: column;
}
.productWrapperConfirm .price__info-newPrice {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #f2673a;
}
.productWrapperConfirm .price__info-newPrice span {
  font-size: 18px;
  line-height: 21px;
}
.productWrapperConfirm .price__info-oldPrice {
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #b5b5c3;
}

.productWrapperConfirm .price__info-promocode {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  color: #464e5f;
}
.productWrapperConfirm .price__info-promocode .text {
  font-size: 12px;
  line-height: 16px;
}
.productWrapperConfirm .price__info-promocode .sale {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.confirmcondition {
  padding-top: 14px;
}

.confirmcondition:not(.confirmcondition ~ .confirmcondition) {
  border-top: 1px solid #e5eaee;
}

.confirmcondition input {
  display: none;
}

.confirmcondition span {
  margin-top: 3px;
}

.confirmcondition label {
  font-size: 12px;
  line-height: 16px;
  position: relative;
  font-weight: 400;
}

.confirmcondition label::before {
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d6d6e0;
  margin-right: 18px;
  background-color: white;
  flex: none;
}
.confirmcondition input:checked + label::before {
  background-color: #47c8f5;
  border-color: #47c8f5;
}
.confirmcondition label::after {
  content: '\e90b';
  font-family: 'icomoon', sans-serif;
  color: white;
  position: absolute;
  left: 3px;
  font-size: 14px;
  top: 10px;
  flex: none;
}

.confirmcondition label a {
  color: #47c8f5;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .productWrapperConfirm .main__conditions-item {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .productWrapperConfirm .main__conditions-item {
    width: 100%;
  }
  .productWrapperConfirm .checkout_confirm-item {
    width: 100%;
    margin-bottom: 10px;
  }
  .checkout_confirm-item-heading {
    margin-bottom: 8px;
  }
  .checkout_confirm-item-heading h6 {
    font-size: 12px;
  }
  .checkout_confirm-item-edit {
    display: none;
  }
  .checkout_confirm-item-group {
    font-size: 12px;
    line-height: 14px;
  }
  .checkout_confirm-item-groupItem {
    margin: 4px 0;
  }
  .productWrapperConfirm .main__conditions {
    margin: 12px -15px 12px -15px;
  }
}
