.productWrapper-admin img {
  max-width: 100%;
}

.productWrapper .main__conditions {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 24px -15px 32px -15px;
  padding-bottom: 10px;
}
.productWrapper .main__conditions-item {
  width: 33.332%;
  padding: 0 15px;
  margin-bottom: 15px;
}

.productWrapper .main__conditions-item-label {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.productWrapper .main__conditions-item-text {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.productWrapper .main__conditions-item-text span {
  color: #b5b5c3;
  font-size: 16px;
  font-weight: normal;
}
.productWrapper .additional__info {
  padding-top: 32px;
  border-top: 1px solid #ecf0f3;
  white-space: pre-line;
}
.productWrapper .additional__info h3 {
  margin-bottom: 16px;
}
.productWrapper .additional__info h6 {
  margin-bottom: 16px;
}
.productWrapper .additional__info p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #1f2334;
  white-space: pre-line;
}

.productWrapper .additional__info .rules_link {
  font-size: 12px;
  line-height: 16px;
  color: #47c8f5;
}
.productWrapper .additional__info .rules_link:hover {
  text-decoration: underline;
}

.productWrapper .total__info {
  margin-top: 32px;
  margin-bottom: 48px;
}

.productWrapper .price__info {
  margin-top: 24px;
  background: #f3f6f9;
  border: 1px solid #e5eaee;
  border-radius: 4px;
  padding: 16px 24px 15px 24px;
  display: flex;
  align-items: flex-start;
}

.productWrapper .price__info-price {
  display: flex;
  flex-direction: column;
}
.productWrapper .price__info-newPrice {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #f2673a;
}
.productWrapper .price__info-newPrice span {
  font-size: 18px;
  line-height: 21px;
}
.productWrapper .price__info-oldPrice {
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #b5b5c3;
}

.productWrapper .price__info-promocode {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  color: #464e5f;
}
.productWrapper .price__info-promocode .text {
  font-size: 12px;
  line-height: 16px;
}
.productWrapper .price__info-promocode .sale {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 767px) {
  .productWrapper .main__conditions-item {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .productWrapper .main__conditions-item {
    width: 100%;
  }
  .productWrapper .main__conditions {
    margin: 12px -15px 12px -15px;
  }
}
