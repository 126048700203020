.promocodeWrapper-flex {
  display: flex;
  align-items: center;
}
.promocodeWrapper input.form-control {
  width: 280px;
  max-width: 100%;
  margin-right: 32px;
  padding-right: 55px;
}

.promocodeWrapper .error {
  color: #f64e60;
  font-size: 12px;
  font-weight: 400;
}

.promocodeWrapper .promocodeInfo {
  background-color: #d7f9ef;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 22px;
}

.promocodeWrapper .promocodeCancel {
  margin-left: 32px;
  padding: 12px 22px;
  color: #47c8f5;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  background-color: transparent;
}
.promocodeWrapper .promocodeCancel:focus {
  outline: 0;
  border: 0;
  background-color: transparent;
}

.promocodeWrapper .wrapp_input {
  position: relative;
}
.promocodeWrapper .wrapp_input.active::before {
  content: '';
  width: 23px;
  height: 23px;
  border: 1px solid #2ba579;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
}

.promocodeWrapper .wrapp_input.active::after {
  content: '\e90b';
  font-family: icomoon, sans-serif;
  position: absolute;
  top: calc(50% + 1px);
  right: 53px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #2ba579;
}

@media screen and (max-width: 767px) {
  .promocodeWrapper {
    &-flex {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .wrapp_input {
      width: 100%;
      margin-bottom: 8px;
      &.active {
        &::before {
          right: 10px;
        }
        &::after {
          right: 14px;
        }
      }
    }
    input {
      &.form-control {
        width: 100%;
        margin-right: 0;
      }
    }
    .promocodeCancel {
      margin-left: 15px;
      padding: 12px 0;
    }
    .btn {
      width: 100%;
    }
  }
}
