.react-date-picker {
  width: 100%;
  &__wrapper {
    padding: 0 24px 0 24px;
    width: 100%;
    border: 1px solid #d6d6e0;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    height: 48px;
    span {
      color: #b5b5c3;
    }
    input {
      color: #495057;
      &:invalid {
        background: unset !important;
      }
      &::placeholder {
        font-size: 16px;
        color: #b5b5c3;
      }
    }
  }
}
.react-calendar__tile--now {
  background: unset;
}

.react-calendar__navigation__label:focus,
.react-calendar__navigation__arrow:focus {
  background-color: #fff !important;
  outline: 0;
}
.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #47c8f5 !important;
  outline: 0;
}
.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  background-color: #ffcd34 !important;
}

.react-date-picker__inputGroup {
  min-width: calc((7px * 3) + 4.54em * 5 + 0.417em * 2) !important;
}
button.react-date-picker__calendar-button.react-date-picker__button {
  &::after {
    content: '\e910';
    color: #61696d;
    font-family: icomoon, sans-serif;
  }
  svg {
    display: none;
  }
  &:focus {
    outline: none;
  }

  right: 8px;
  position: absolute;
  top: 8px;
}
.react-date-picker--open .react-date-picker__wrapper {
  border: 1px solid #47c8f5 !important;
}
