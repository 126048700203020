.select__control:hover {
  border-color: #d6d6e0 !important;
}

.select__control--menu-is-open .select__indicator {
  transform: rotate(180deg);
}

.select__indicator svg {
  display: none !important;
}
.select__indicator {
  width: 40px;
  height: 40px;
  background: url('/media/pib/dropdown.png');
  background-repeat: no-repeat;
  background-position: center;
}

.formio-component-custom_select.formio-error-wrapper .select__control {
  border-color: #f64e60 !important;
}

@media screen and (max-width: 767px) {
  .formio-component-custom_select {
    .select__value-container {
      padding: 8px 24px;
      font-size: 12px;
      line-height: 16px;
    }
    .select__menu {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
