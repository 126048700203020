.formioRadioRow {
  margin-bottom: 24px;
}
.formioRadioRow-wrapper {
  display: flex;
  align-items: center;
}
.formioRadioRow .title {
  font-size: 16px;
  line-height: 24px;
  margin-right: 18px;
}

.formioRadioRow .fa {
  margin-right: 36px;
}

.formioRadioRow-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.formioRadioRow-item.blocked input:checked + label {
  border-color: #f64e60;
  background-color: #f64e60;
}

.formioRadioRow-item input {
  display: none;
}

.formioRadioRow-item input:checked + label {
  background-color: #47c8f5;
  border-color: #47c8f5;
  color: white;
}

.formioRadioRow-item label {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  padding: 8px 22px;
  margin: 0;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.formioRadioRow-item:first-child label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: -1px;
}
.formioRadioRow-item:last-child label {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.formioRadioRow .info_mark {
  margin-left: 15px;
  position: relative;
}

.formioRadioRow .info_mark:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
}
.formioRadioRow .info_mark .tooltip {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  pointer-events: none;
}
.formioRadioRow .info_mark .tooltip-inner {
  margin-left: 8px;
}

.blockedAlert {
  font-size: 10px;
  color: #f64e60;
}

@media screen and (max-width: 767px) {
  .formioRadioRow {
    margin-bottom: 0;
    .title {
      font-size: 12px;
      line-height: 16px;
      padding: 5px 0 0 0;
      margin-bottom: 5px;
    }
    &-item {
      width: 100%;
      label {
        font-size: 12px;
        line-height: 16px;
        padding-top: 7px 22px;
      }
    }
    &-wrapper {
      flex-wrap: wrap;
    }
  }
}
