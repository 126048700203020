.formio-component-rangeslider {
  display: flex;
  flex-direction: column;
}

.formio-component-rangeslider .form-control.right-icon-rub {
  padding-right: 40px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  width: 175px;
  text-align: right;
}

.formio-component-rangeslider > div {
  width: 100%;
}

.formio-component-rangeslider .MuiSlider-root {
  width: calc(100% - 30px);
  height: 8px;
  margin-bottom: 35px;
  margin-left: 15px;
  margin-top: 15px;
  box-sizing: border-box;
}

.formio-component-rangeslider .MuiSlider-root .MuiSlider-rail {
  background-color: #e5eaee;
  opacity: 1;
  height: 8px;
  border-radius: 20px;
  width: calc(100% + 15px);
}

.formio-component-rangeslider .MuiSlider-root .MuiSlider-mark {
  display: none;
}
.formio-component-rangeslider .MuiSlider-root .MuiSlider-track {
  background-color: #47c8f5;
  opacity: 1;
  height: 8px;
  border-radius: 20px;
  margin-left: -15px;
}
.formio-component-rangeslider .MuiSlider-root .MuiSlider-thumb {
  background-color: white;
  border: 6px solid #edeced;
  width: 32px;
  height: 32px;
  margin-top: -13px;
  margin-left: -17px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.formio-component-rangeslider .MuiSlider-root .MuiSlider-markLabel {
  color: #80808f;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  top: 45px;
}
.formio-component-rangeslider .MuiSlider-root .MuiSlider-markLabel::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 4px;
  background: #80808f;
  left: 50%;
  top: -5px;
}

@media screen and (max-width: 991px) {
  .formio-component-rangeslider {
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .formio-component-rangeslider .MuiSlider-root {
    margin-top: 5px;
    margin-bottom: 25px;
  }
  .formio-component-rangeslider .MuiSlider-root .MuiSlider-markLabel {
    font-size: 10px;
  }
}
