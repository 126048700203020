.formio-error-wrapper {
  .dadataInputContainer {
    & > div {
      border-color: #dc3545;
    }
    .form-control,
    .input-field {
      border-color: #dc3545;
    }
  }
}

.dadataWrapper {
  margin-bottom: 24px;
  input {
    z-index: 99999;
  }
  .checkboxWithInput {
    margin-bottom: 24px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .field-wrapper {
    label {
      width: 100%;
    }
  }
  .input-field {
    padding: 0 24px 0 24px;
    width: 100%;
    border: 1px solid #d6d6e0;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    height: 48px;
  }
  .container-fields-wrapper {
    margin-bottom: 24px;
    .label {
      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);
      font-size: inherit;
      margin-bottom: 8px;
      line-height: 1.5;
    }
  }

  @media (max-width: 767px) {
    .container-fields-wrapper {
      display: block !important;
      margin-bottom: 15px;
    }
    .field-wrapper {
      flex: none !important;
      margin: 0 !important;
      label {
        justify-content: start !important;
      }
    }
    .input-field {
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
    .dadataInputContainer {
      font-size: 12px;
      line-height: 16px;
      & > div:not(.field-wrapper) {
        min-height: 40px;
      }
    }
  }
}
