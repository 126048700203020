.otp-controlWrapper {
  display: flex;
  align-items: center;
  .btn:disabled {
    opacity: 1;
    background: #d6d6e0;
    color: #464e5f;
  }
}
.otp-controlWrapper .form-control {
  width: 220px;
  max-width: 100%;
  padding-right: 55px;

  /* background: url("/media/bg/bg-1.jpg"); */
}

.otp-Wrapper .errorLabel {
  font-size: 10px;
  color: #f64e60;
  width: 220px;
}

.otp-form-control-wrapper {
  position: relative;
  margin-right: 24px;
}
.otp-form-control-wrapper.approved::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #04aa77;
}
.otp-form-control-wrapper.approved::after {
  content: '\e90b';
  font-family: icomoon, sans-serif;
  position: absolute;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  right: 14px;
  font-size: 12px;
  color: #04aa77;
}
.otp-approvedText {
  color: #04aa77;
  font-size: 16px;
  line-height: 24px;
}

.otp-codeWrapper {
  margin-top: 24px;
}
.otp-codeWrapper .text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #464e5f;
  margin-bottom: 8px;
}

.otp-code-control-wrapper {
  display: flex;
  align-items: center;
}

.otp-Wrapper .sms-code-form-controll {
  width: 133px;
  margin-right: 15px;
}

.otp-Wrapper .sms-code-button {
  width: 74px;
}

.otp-code-btns {
  background-color: transparent !important;
  color: #47c8f5 !important;
  margin-left: 30px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.otp-code-btns:focus,
.otp-code-btns:active,
.otp-code-btns:focus:active {
  background-color: transparent !important;
  outline: 0 !important;
  color: #80808f !important;
  box-shadow: none !important;
}
.otp-code-btns:hover {
  color: #80808f !important;
}

@media screen and (max-width: 767px) {
  .otp-controlWrapper {
    flex-direction: column;
    align-items: flex-start;
    .form-control {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }

  .otp-form-control-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .otp-code-control-wrapper {
    flex-wrap: wrap;
  }
  .otp-code-btns {
    margin-left: 0;
  }
}
