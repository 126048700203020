.tree_wrapper h6 {
  font-weight: bold;
  color: black;
}
.tree_wrapper ul {
  list-style: none;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tree_wrapper ul li {
  display: flex;
  align-items: center;
}
.tree_wrapper ul li input {
  margin-right: 5px;
}
.tree_wrapper ul li label {
  margin-bottom: 0;
}

.tree_wrapper .tree-heading {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}
.tree_wrapper .tree-heading span {
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  width: 8px;
  text-align: center;
  color: darkred;
}
.tree_wrapper .tree-heading span.active {
  color: #00a000;
}
