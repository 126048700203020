.formioCheckboxWrapper {
  margin-bottom: 18px;
}
.formioCheckboxRow {
  display: flex;
  align-items: center;
}

.formioCheckboxRow input {
  display: none;

  &:disabled {
    & ~ label,
    & ~ .fa {
      opacity: 0.5;
    }
  }
}
.formioCheckboxRow label {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
  display: flex;
}
.formioCheckboxRow label::before {
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d6d6e0;
  margin-right: 25px;
  background-color: white;
  flex: none;
}
.formioCheckboxRow input:checked + label::before {
  background-color: #47c8f5;
  border-color: #47c8f5;
}
.formioCheckboxRow label::after {
  content: '\e90b';
  font-family: 'icomoon', sans-serif;
  color: white;
  position: absolute;
  left: 3px;
  font-size: 14px;
  top: 1px;
}
.formioCheckboxRow .info_mark {
  margin-left: 15px;
  position: relative;
}

.formioCheckboxRow .info_mark:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
}
.formioCheckboxRow .info_mark .tooltip {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  pointer-events: none;
}
.formioCheckboxRow .info_mark .tooltip-inner {
  margin-left: 8px;
}

.blockedAlert {
  font-size: 10px;
  color: #f64e60;
}

@media screen and (max-width: 767px) {
  .formioCheckboxRow {
    label {
      font-size: 12px;
      line-height: 16px;
      flex: 1;
      &::before {
        margin-right: 9px;
      }
      &::after {
        top: 4px;
      }
    }
  }
}
